<template>
  <div class="production">
    <h1>Production</h1>
    <br>
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
   <a href="http://kontexbi.com/ibot/ibot.php">Click Here to run Production Code
</a>
  </div>
</template>
<script>
export default {
  computed: {
    localauth() {
      return this.$store.state.authenticated;
    },
  },
  mounted() {
    if (!this.localauth) {
      this.$router.push("/");
    }
  },
};
</script>
